import React, { useRef, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
  Navigation,
  Autoplay,
  Pagination,
  EffectFade,
} from 'swiper';

import { ImageThumbnail, StringField } from '@/typings/model';
import BannerCard from '@/modules/Home/components/BannerCard';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import Spinner from '@/components/Spinner';
import useWindowSize from '@/hooks/useWindowSize';

SwiperCore.use([Navigation, Autoplay, Pagination, EffectFade]);

type Props = {
  salesList?: Array<{
    image: ImageThumbnail;
    imageDesktop?: ImageThumbnail;
    title: StringField;
    imageTitle?: StringField;
    link: StringField;
  }>;
};

function SalesSlider({ salesList }: Props) {
  const size = useWindowSize();

  const sliderPagination = useRef<HTMLInputElement>(null);
  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);

  return (
    <Component>
      <SliderContainer>
        {salesList ? (
          <>
            <Swiper
              initialSlide={0}
              slidesPerView={1}
              speed={500}
              autoplay={{ delay: 4000, disableOnInteraction: false }}
              loop={true}
              pagination={{
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true,
              }}
              onSlideChange={(swiper) =>
                setActiveSlideIndex(swiper.activeIndex)
              }
            >
              {salesList?.map((salesItem, index) => {
                if (!salesItem.image) return null;
                return (
                  <SwiperSlide key={index}>
                    <SwiperSlideInner>
                      <BannerCard
                        image={
                          size.width < 768
                            ? salesItem.image
                            : salesItem.imageDesktop
                            ? salesItem.imageDesktop
                            : salesItem.image
                        }
                        text={salesItem.title}
                        title={size.width > 767 ? salesItem.imageTitle : null}
                        link={salesItem.link}
                        isSlider={true}
                      />
                    </SwiperSlideInner>
                  </SwiperSlide>
                );
              })}
            </Swiper>
            <ItemsPagination
              className="swiper-pagination"
              ref={sliderPagination}
            />
          </>
        ) : (
          <Spinner color="black" absolute={true} />
        )}
      </SliderContainer>
    </Component>
  );
}

const Component = styled.div`
  width: 100%;
  height: 100%;
`;

const anim = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
`;

const SliderContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  .swiper-container {
    width: 100%;
    height: 100%;
  }
  .swiper-pagination {
    position: absolute;
    top: 29px;
    left: 0;
    height: 6px;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;

    ${media.laptop(css`
      top: 16px;
    `)}

    ${media.mobileLarge(css`
      top: auto;
      bottom: 30px;
    `)}

    .swiper-pagination-bullet {
      position: relative;
      width: 100%;
      height: 6px;
      border-radius: 0;
      background: ${colors.white50};
      opacity: 1;
      margin-left: 2px;

      ${media.laptopOnly(css`
        height: 4px;
      `)}

      ${media.laptop(css`
        height: 4px;
      `)}

      ${media.tablet(css`
        height: 3px;
      `)}
      
      ${media.mobileLarge(css`
        height: 4px;
      `)}

      &:first-child {
        margin-left: 0;
      }
      &:before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 0;
        content: '';
        background-color: ${colors.white};
        opacity: 1;
      }
    }
    .swiper-pagination-bullet-active {
      position: relative;
      overflow: hidden;

      &:before {
        content: '';
        position: absolute;
        width: 100%;
        will-change: transform;
        animation: ${anim} 4350ms linear;
      }
    }
  }
`;

const SwiperSlideInner = styled.div`
  width: 100%;
  height: 100%;
`;

const ItemsPagination = styled.div``;

export default SalesSlider;
