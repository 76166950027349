import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import useWindowSize from '@/hooks/useWindowSize';

type ThelpfulData = {
  title: string;
  image: {
    url: string;
  };
  link: string;
};

type Props = {
  helpfulInformation: ThelpfulData[];
};

function HelpfulInfo({ helpfulInformation }: Props) {
  const windowSize = useWindowSize();

  return (
    <Component>
      <Inner>
        {helpfulInformation.map((item) => (
          <>
            <Card
              marginLeft={windowSize.width > 768 ? '0px' : '24px'}
              href={item.link}
            >
              <Content>
                <Icon src={item.image.url} />
                <Title>{item.title}</Title>
              </Content>
            </Card>
            <Space />
          </>
        ))}
      </Inner>
    </Component>
  );
}

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: start;
  white-space: pre-wrap;
`;
const Space = styled.div`
  width: 40px;
  height: 10px;
`;

const Component = styled.div`
  margin-top: -5px;
  padding: 25px 0 110px;
  background: ${colors.white};
  position: relative;

  ${media.laptop(css`
    padding: 0 0 132px;
  `)}

  ${media.mobile(css`
    padding: 0 0 50px;
  `)}
`;

const Icon = styled.img`
  width: 62px;
  height: 62px;
  margin-right: 35px;

  ${media.laptopOnly(css`
    width: 55px;
    height: 55px;
  `)}

  ${media.mobileSmall(css`
    width: 40px;
    height: 36px;
  `)}

  ${media.mobile(css`
    width: 40px;
    height: 36px;
    margin-right: 0px;
  `)}
`;

const Title = styled.a`
  color: #020202;
  font-size: 25px;
  font-weight: 500;
  width: 150px;

  ${media.laptopOnly(css`
    font-size: 20px;
  `)}

  ${media.tabletOnly(css`
    font-size: 22px;
  `)}  
    
  ${media.mobile(css`
    font-size: 12px;
    line-height: 15px;
    margin-left: 12px;
    padding-right: 24px;
  `)}

  ${media.mobileSmall(css`
    font-size: 12px;
    line-height: 15px;
    margin-left: 12px;
  `)}
`;

const Card = styled.a<{ marginLeft: string }>`
  display: flex;
  background: #f1f1f1;
  align-content: center;
  justify-content: start;
  align-items: center;
  flex-direction: row;
  text-align: center;
  height: 121px;
  width: 366px;
  padding-left: 34px;
  margin-top: 24px;
  margin-left: 14px;

  &:hover {
    background: #e3e3e3;
    transition: all 0.3s linear;
    cursor: pointer;
  }

  ${media.laptopOnly(css`
    height: 91px;
    width: 275px;
  `)}

  ${media.tabletOnly(css`
    height: 115px;
    width: 349px;
  `)}


  ${media.mobile(css`
    height: 65px;
    width: 197px;
  `)}
`;

const Inner = styled.div`
  display: flex;
  justify-content: center;
  overflow-x: auto;
  white-space: nowrap;
  padding: 10px;
  gap: 10px;

  @media (max-width: 768px) {
    padding: 10px 0;
  }

  @media (min-width: 768px) and (max-width: 3000px) {
    justify-content: center;
    flex-wrap: wrap;
  }

  @media (max-width: 767px) {
    justify-content: start;
    flex-wrap: nowrap;
  }
`;

export default HelpfulInfo;
