import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

type Props = {
  title: string;
  link: string;
};

function BtnAllDiscount({ title, link }: Props) {
  return (
    <Component>
      <Inner>
        <Card href={link}>
          <Title>{title}</Title>
        </Card>
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  margin-top: -5px;
  background: ${colors.white};
  position: relative;
  padding-top: 48px;
  padding-bottom: 100px;
  ${media.sizeTablet(css`
    padding-bottom: 60px;
  `)}
  ${media.sizeMobile(css`
    padding-bottom: 40px;
  `)}
`;

const Title = styled.a`
  color: #020202;
  font-size: 36px;
  font-weight: 700;

  ${media.sizeTablet(css`
    font-size: 24px;
    font-weight: 700;
  `)}

  ${media.sizeMobile(css`
    font-size: 20px;
  `)}
  ${media.sizeSmallMobile(css`
    font-size: 20px;
  `)}
`;

const Card = styled.a`
  height: 90px;
  width: 83.3%;
  margin: 0 auto;
  background: #e4ff41;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.5s ease;

  &:hover {
    background: #dcfa2a;

    cursor: pointer;
  }

  ${media.laptop(css`
    width: 96%;
  `)}

  ${media.sizeTablet(css`
    height: 80px;
  `)}

  ${media.sizeMobile(css`
    height: 60px;
  `)}
  ${media.sizeSmallMobile(css`
    height: 60px;
  `)}
`;

const Inner = styled.div`
  background: white;
`;

export default BtnAllDiscount;
